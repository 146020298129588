import { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import Page from '../../components/Page';
import { ChangePasswordForm } from '../../components/authentication/change-password';
import { useLocation } from 'react-router-dom';
function urlToJson(url) {
  if (url) {
    return JSON.parse('{"' + decodeURI(url)?.replace(/"/g, '\\"')?.replace(/&/g, '","')?.replace(/=/g, '":"') + '"}');
  } else {
    return { email: '', user: '' };
  }
}

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function ChangePassword() {
  const location = useLocation();
  const token = location?.pathname.split('/')[4];
  const { email, user } = urlToJson(location?.search?.substring(1) || '');

  return (
    <RootStyle title="Reset Password | mPower">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <>
            <ChangePasswordForm token={token} email={email.replace('%40', '@')} userType={user} />
          </>
        </Box>
      </Container>
    </RootStyle>
  );
}
