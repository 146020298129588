import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
import { API_URL } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingGroup: false,
  error: false,
  userList: [],
  groupList: [],
  exerciseList: [],
  trainersList: [],
  workoutList: [],
  mealsList: [],
  programsList: [],
  getData: [],
  filterUser: [],
  groupItem: null,
  userItem: null,
  userBody: [],
  userPrograms: [],
  exerciseType: [],
  muscleType: [],
  exercisesItem: null,
  workoutTypes: [],
  workoutItem: [],
  trainerItem: null,
  genderList: [],
  foodList: [],
  mealsCategories: [],
  mealItem: null,
  partnerList: [],
  getListData: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getGroupListSuccess(state, action) {
      state.isLoading = false;
      state.groupList = action.payload;
    },

    getTrainersListSuccess(state, action) {
      state.isLoading = false;
      state.trainersList = action.payload;
    },

    getExerciseListSuccess(state, action) {
      state.isLoading = false;
      state.exerciseList = action.payload;
    },

    getWorkoutListSuccess(state, action) {
      state.isLoading = false;
      state.workoutList = action.payload;
    },

    getMealsListSuccess(state, action) {
      state.isLoading = false;
      state.mealsList = action.payload;
    },

    getProgramsListSuccess(state, action) {
      state.isLoading = false;
      state.programsList = action.payload;
    },

    getDataListSuccess(state, action) {
      state.isLoading = false;
      state.getData = action.payload;
    },

    getFilterUserListSuccess(state, action) {
      state.isLoading = false;
      state.filterUser = action.payload;
    },
    createGroupSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingGroup = action.payload;
    },
    getGroupItemSuccess(state, action) {
      state.isLoading = false;
      state.groupItem = action.payload;
    },
    getUserByIdSuccess(state, action) {
      state.isLoading = false;
      state.userItem = action.payload;
    },
    GetUserBodyByIdSuccess(state, action) {
      state.isLoading = false;
      state.userBody = action.payload;
    },
    GetProgramsUserByIdSuccess(state, action) {
      state.isLoading = false;
      state.userPrograms = action.payload;
    },
    getExerciseTypeSuccess(state, action) {
      state.isLoading = false;
      state.exerciseType = action.payload;
    },
    getMuscleTypeSuccess(state, action) {
      state.isLoading = false;
      state.muscleType = action.payload;
    },
    GetExercisesByIdSuccess(state, action) {
      state.isLoading = false;
      state.exercisesItem = action.payload;
    },
    GetWorkoutTypesSuccess(state, action) {
      state.isLoading = false;
      state.workoutTypes = action.payload;
    },
    GetWorkoutByIdSuccess(state, action) {
      state.isLoading = false;
      state.workoutItem = action.payload;
    },
    getTrainerByIdSuccess(state, action) {
      state.isLoading = false;
      state.trainerItem = action.payload;
    },
    GetGenderListSuccess(state, action) {
      state.isLoading = false;
      state.genderList = action.payload;
    },
    GetMealsFilterSuccess(state, action) {
      state.isLoading = false;
      state.foodList = action.payload;
    },
    GetMealsCategorySuccess(state, action) {
      state.isLoading = false;
      state.mealsCategories = action.payload;
    },
    GetMealsByIdSuccess(state, action) {
      state.isLoading = false;
      state.mealItem = action.payload;
    },
    getPartnerListSuccess(state, action) {
      state.isLoading = false;
      state.partnerList = action.payload;
    },
    GetListDataSuccess(state, action) {
      state.isLoading = false;
      state.getListData = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getUserList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/v2/users`, { page: page, role: 'user' });
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPartnerList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/v2/users`, { page: page, role: 'partner' });
      dispatch(slice.actions.getPartnerListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getGroupList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/group?page=${page}`);
      dispatch(slice.actions.getGroupListSuccess(response.data.groups));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getTrainersList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/v2/users`, { page: page, role: 'trainer' });
      dispatch(slice.actions.getTrainersListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTrainerById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/trainers/${id}`);

      dispatch(slice.actions.getTrainerByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getExerciseList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/exercise?page=${page}`);
      dispatch(slice.actions.getExerciseListSuccess(response.data.exercises));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getWorkoutList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/workout?page=${page}`);
      dispatch(slice.actions.getWorkoutListSuccess(response.data.workouts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getMealsList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/meal?page=${page}`);
      dispatch(slice.actions.getMealsListSuccess(response.data.meals));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getProgramsList(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/program?page=1`);
      dispatch(slice.actions.getProgramsListSuccess(response.data.programs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getDataList(fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/getData`, fields);
      delete response?.data.status;
      dispatch(slice.actions.getDataListSuccess(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getFiltersUser(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/v2/users?page=1`, filters);
      dispatch(slice.actions.getFilterUserListSuccess(response.data.users.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
//
// ----------------------------------------------------------------------
export function createGroup(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const imageFile = params?.groupImage;
      params.groupImage = {};
      const response = await axios.post(`${API_URL}/v2/group`, params);
      const imageId = await uploadImage(`${API_URL}/upload/image`, imageFile);
      await imageGroup(imageId, response.data.group.id);
      dispatch(slice.actions.createGroupSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const uploadImage = async (url, file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(url, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {}
};

export const imageGroup = async (imageId, groupId) => {
  try {
    const response = await axios.post(`${API_URL}/images/crop/image-group`, {
      file: imageId,
      group_id: groupId
    });
    return response;
  } catch (e) {}
};

export function GetGroupById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/group/${id}`);
      dispatch(slice.actions.getGroupItemSuccess(response.data.group));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetUserById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/users/${id}`);
      dispatch(slice.actions.getUserByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetUserBodyById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/profile/get?user_id=${id}`);
      dispatch(slice.actions.GetUserBodyByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetProgramsUserById(page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let data = {
        fields: {
          field: 'programs'
        }
      };
      const response = await axios.post(`${API_URL}/getData?page=${page}`, data);
      dispatch(slice.actions.GetProgramsUserByIdSuccess(response.data.programs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setProgramsUserAccess(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/programs/detachFrom`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExerciseType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/exercise-type`);
      dispatch(slice.actions.getExerciseTypeSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMuscleType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/muscle-type`);
      dispatch(slice.actions.getMuscleTypeSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetExercisesById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/exercise/${id}`);
      dispatch(slice.actions.GetExercisesByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetWorkoutTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/workout-types`);
      dispatch(slice.actions.GetWorkoutTypesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetWorkoutById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/workout/${id}`);
      dispatch(slice.actions.GetWorkoutByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetMealsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/meal/${id}`);
      dispatch(slice.actions.GetMealsByIdSuccess(response?.data?.meal));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetGenderList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/getData`, { fields: ['genders'] });
      delete response?.data.status;
      dispatch(slice.actions.GetGenderListSuccess(response?.data?.genders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// const groupBy = (items, key) =>
//   items.reduce(
//     (result, item) => ({
//       ...result,
//       [item[key]]: [...(result[item[key]] || []), item]
//     }),
//     {}
//   );

export function GetMealsFilter(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/v2/meal/items`, data);
      dispatch(slice.actions.GetMealsFilterSuccess(response?.data?.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetMealsCategoryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${API_URL}/getData`, {
        fields: ['liquid-categories', 'food-categories']
      });

      delete response?.data.status;

      dispatch(
        slice.actions.GetMealsCategorySuccess({
          food: response.data['food-categories'],
          liquid: response.data['liquid-categories']
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetListData(path, key) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URL}/v2/${path}`);
      dispatch(slice.actions.GetListDataSuccess(response.data[key]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
