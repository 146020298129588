import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <path
          d="M93.9658 414.16L382.599 125.527C424.68 83.4459 492.906 83.4459 534.987 125.527L550 140.54L185.173 505.367L93.9658 414.16Z"
          fill="#42CE10"
        />
        <path
          d="M461.558 325.717L476.57 340.73C518.651 382.81 518.651 451.037 476.57 493.117L461.558 508.13L370.351 416.923L461.558 325.717Z"
          fill="#42CE10"
        />
        <path
          d="M0 228.987L197.427 31.5606C239.507 -10.5201 307.734 -10.5202 349.814 31.5605L364.827 46.5735L91.2069 320.194L0 228.987Z"
          fill="#42CE10"
        />
      </svg>
    </Box>
  );
}
