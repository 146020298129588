import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import flashFill from '@iconify/icons-eva/flash-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Button, Box, Link, Container, Typography, Stack } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import { varFadeIn, varFadeInUp, varWrapEnter, varFadeInRight } from '../../animate';
import LogoOnlyLayout from '../../../layouts/LogoOnlyLayout';
import Logo from '../../Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  flexDirection: 'row',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'row'
  }
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 520,
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left'
  }
}));

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '8%',
    width: 'auto',
    height: '48vh'
  }
}));

// ----------------------------------------------------------------------

export default function LandingHero() {
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroOverlayStyle alt="overlay" src="/static/overlay.svg" variants={varFadeIn} />

        <HeroImgStyle alt="hero" src="/static/home/hero.png" variants={varFadeInUp} />

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInRight}>
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 512 512">
                <path
                  d="M93.9658 414.16L382.599 125.527C424.68 83.4459 492.906 83.4459 534.987 125.527L550 140.54L185.173 505.367L93.9658 414.16Z"
                  fill="#42CE10"
                />
                <path
                  d="M461.558 325.717L476.57 340.73C518.651 382.81 518.651 451.037 476.57 493.117L461.558 508.13L370.351 416.923L461.558 325.717Z"
                  fill="#42CE10"
                />
                <path
                  d="M0 228.987L197.427 31.5606C239.507 -10.5201 307.734 -10.5202 349.814 31.5605L364.827 46.5735L91.2069 320.194L0 228.987Z"
                  fill="#42CE10"
                />
              </svg>
              <Typography variant="h1" sx={{ color: 'primary.main' }}>
                mPower
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Typography sx={{ color: 'common.white' }}>
                Welcome to mPower. Your starting point to have a perfect body.
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to={`/auth/login`}
                startIcon={<Icon icon={flashFill} width={20} height={20} />}
              >
                For Partners
              </Button>
            </motion.div>

            <Stack direction="row" spacing={1.5} justifyContent={{ xs: 'center', md: 'flex-start' }}>
              <motion.img height={50} variants={varFadeInRight} src="/static/home/app_store.svg" />
              <motion.img height={50} variants={varFadeInRight} src="/static/home/google_play.svg" />
            </Stack>
            <motion.div variants={varFadeInRight}>
              <Typography sx={{ color: 'common.white' }}>
                <RouterLink to={'privacy-policy'}>Privacy policy</RouterLink> |{' '}
                <RouterLink to={'terms-and-condition'}>Terms and condition</RouterLink>
              </Typography>
            </motion.div>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </>
  );
}
