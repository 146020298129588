import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import { SeverErrorIllustration } from '../assets';
import { LandingAdvertisement, LandingHero } from '../components/_external-pages/landing';

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname?.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { path: '/', element: <LandingHero /> },
        // { path: '/', element: <Navigate to="/auth/login" replace /> },
        { path: '/api/auth/password/reset/:params', element: <ChangePassword /> }, // TODO: Remove, left for testing
        { path: '/payments/stripe/failed' },
        { path: '/auth/complete', element: <VerifyCode /> },
        { path: '/privacy-policy', element: <PrivacyPolicy /> },
        { path: '/terms-and-condition', element: <TermsAndCondition /> }
      ]
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'complete', element: <VerifyCode /> },
        { path: 'password/reset/:params', element: <ChangePassword /> }
      ]
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/admin', element: <Navigate to="/admin/app" replace /> },
        { path: 'app', element: <GeneralAdmin /> },
        { path: 'profile', element: <UserAccount /> },

        { path: 'transaction', element: <TransactionList /> },

        { path: 'food/list', element: <FoodList /> },
        { path: 'food/add', element: <FoodAdd /> },
        { path: 'food/edit/:id', element: <FoodEdit /> },

        { path: 'food-type/list', element: <FoodTypeList /> },
        { path: 'food-type/add', element: <FoodTypeAdd /> },
        { path: 'food-type/edit/:id', element: <FoodTypeEdit /> },

        { path: 'partner/list', element: <PartnerList /> },
        { path: 'partner/show/:id', element: <PartnerShow /> },

        { path: 'trainer-type/list', element: <TrainerTypeList /> },
        { path: 'trainer-type/add', element: <TrainerTypeAdd /> },
        { path: 'trainer-type/edit/:id', element: <TrainerTypeEdit /> },

        { path: 'liquid-category/list', element: <LiquidCategoryList /> },
        { path: 'liquid-category/add', element: <LiquidCategoryAdd /> },
        { path: 'liquid-category/edit/:id', element: <LiquidCategoryEdit /> },

        { path: 'weight/list', element: <WeightList /> },
        { path: 'weight/add', element: <WeightAdd /> },
        { path: 'weight/edit/:id', element: <WeightEdit /> },

        { path: 'target/list', element: <TargetList /> },
        { path: 'target/add', element: <TargetAdd /> },
        { path: 'target/edit/:id', element: <TargetEdit /> },

        { path: 'user-level/list', element: <UserLevelList /> },
        { path: 'user-level/add', element: <UserLevelAdd /> },
        { path: 'user-level/edit/:id', element: <UserLevelEdit /> },

        { path: 'activity-level/list', element: <ActivityLevelList /> },
        { path: 'activity-level/add', element: <ActivityLevelAdd /> },
        { path: 'activity-level/edit/:id', element: <ActivityLevelEdit /> },

        { path: 'age-range/list', element: <AgeRangeList /> },
        { path: 'age-range/add', element: <AgeRangeAdd /> },
        { path: 'age-range/edit/:id', element: <AgeRangeEdit /> },

        { path: 'bmi-range/list', element: <BmiRangeList /> },
        { path: 'bmi-range/add', element: <BmiRangeAdd /> },
        { path: 'bmi-range/edit/:id', element: <BmiRangeEdit /> },

        { path: 'exercise-category/list', element: <ExerciseCategoryList /> },
        { path: 'exercise-category/add', element: <ExerciseCategoryAdd /> },
        { path: 'exercise-category/edit/:id', element: <ExerciseCategoryEdit /> },

        { path: 'liquid/list', element: <LiquidList /> },
        { path: 'liquid/add', element: <LiquidAdd /> },
        { path: 'liquid/edit/:id', element: <LiquidEdit /> },

        { path: 'muscle-category/list', element: <MuscleCategoryList /> },
        { path: 'muscle-category/add', element: <MuscleCategoryAdd /> },
        { path: 'muscle-category/edit/:id', element: <MuscleCategoryEdit /> },

        { path: 'height/list', element: <HeightList /> },
        { path: 'height/add', element: <HeightAdd /> },
        { path: 'height/edit/:id', element: <HeightEdit /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/dashboard', element: <Navigate to="app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'users/list', element: <UsersList /> },
        { path: 'users/show/:id', element: <UsersShow /> },
        { path: 'users/add', element: <UsersAdd /> },
        { path: 'users/edit/:id', element: <UsersEdit /> },
        { path: 'users/profile', element: <UserAccount /> },

        { path: 'group/list', element: <GroupList /> },
        { path: 'group/show/:id', element: <GroupShow /> },
        { path: 'group/add', element: <GroupAdd /> },
        { path: 'group/edit/:id', element: <GroupEdit /> },

        { path: 'trainers/list', element: <TrainersList /> },
        { path: 'trainers/show/:id', element: <TrainersShow /> },
        { path: 'trainers/add', element: <TrainersAdd /> },
        { path: 'trainers/edit/:id', element: <TrainersEdit /> },

        { path: 'exercises/list', element: <ExercisesList /> },
        { path: 'exercises/show/:id', element: <ExercisesShow /> },
        { path: 'exercises/add', element: <ExercisesAdd /> },
        { path: 'exercises/edit/:id', element: <ExercisesEdit /> },

        { path: 'workouts/list', element: <WorkOutsList /> },
        { path: 'workouts/show/:id', element: <WorkOutsShow /> },
        { path: 'workouts/add', element: <WorkOutsAdd /> },
        { path: 'workouts/edit/:id', element: <WorkOutsEdit /> },

        { path: 'meals/list', element: <MealsList /> },
        { path: 'meals/show/:id', element: <MealsShow /> },
        { path: 'meals/add', element: <MealsAdd /> },
        { path: 'meals/edit/:id', element: <MealsEdit /> },

        { path: 'programs/list', element: <ProgramsList /> },
        { path: 'programs/show/:id', element: <ProgramsShow /> },
        { path: 'programs/add', element: <ProgramsAdd /> },
        { path: 'programs/edit/:id', element: <ProgramsEdit /> }

        //ADMIN
      ]
    },
    { path: '/404', element: <General404 /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const UsersList = Loadable(lazy(() => import('../pages/users/UsersList')));
const UsersShow = Loadable(lazy(() => import('../pages/users/UsersShow')));
const UsersAdd = Loadable(lazy(() => import('../pages/users/UsersAdd')));
const UsersEdit = Loadable(lazy(() => import('../pages/users/UsersEdit')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

const GroupList = Loadable(lazy(() => import('../pages/group/GroupList')));
const GroupShow = Loadable(lazy(() => import('../pages/group/GroupShow')));
const GroupAdd = Loadable(lazy(() => import('../pages/group/GroupAdd')));
const GroupEdit = Loadable(lazy(() => import('../pages/group/GroupEdit')));

const TrainersList = Loadable(lazy(() => import('../pages/trainers/TrainersList')));
const TrainersShow = Loadable(lazy(() => import('../pages/trainers/TrainersShow')));
const TrainersAdd = Loadable(lazy(() => import('../pages/trainers/TrainersAdd')));
const TrainersEdit = Loadable(lazy(() => import('../pages/trainers/TrainersEdit')));

const ExercisesList = Loadable(lazy(() => import('../pages/exercises/ExercisesList')));
const ExercisesShow = Loadable(lazy(() => import('../pages/exercises/ExercisesShow')));
const ExercisesAdd = Loadable(lazy(() => import('../pages/exercises/ExercisesAdd')));
const ExercisesEdit = Loadable(lazy(() => import('../pages/exercises/ExercisesEdit')));

const WorkOutsList = Loadable(lazy(() => import('../pages/workouts/WorkOutsList')));
const WorkOutsShow = Loadable(lazy(() => import('../pages/workouts/WorkOutsShow')));
const WorkOutsAdd = Loadable(lazy(() => import('../pages/workouts/WorkOutsAdd')));
const WorkOutsEdit = Loadable(lazy(() => import('../pages/workouts/WorkOutsEdit')));

const MealsList = Loadable(lazy(() => import('../pages/meals/MealsList')));
const MealsShow = Loadable(lazy(() => import('../pages/meals/MealsShow')));
const MealsAdd = Loadable(lazy(() => import('../pages/meals/MealsAdd')));
const MealsEdit = Loadable(lazy(() => import('../pages/meals/MealsEdit')));

const ProgramsList = Loadable(lazy(() => import('../pages/programs/ProgramsList')));
const ProgramsShow = Loadable(lazy(() => import('../pages/programs/ProgramsShow')));
const ProgramsAdd = Loadable(lazy(() => import('../pages/programs/ProgramsAdd')));
const ProgramsEdit = Loadable(lazy(() => import('../pages/programs/ProgramsEdit')));

//ADMIN
const FoodAdd = Loadable(lazy(() => import('../pages/admin/food/FoodAdd')));
const FoodList = Loadable(lazy(() => import('../pages/admin/food/FoodList')));
const FoodEdit = Loadable(lazy(() => import('../pages/admin/food/FoodEdit')));

const FoodTypeList = Loadable(lazy(() => import('../pages/admin/foodtype/FoodTypeList')));
const FoodTypeEdit = Loadable(lazy(() => import('../pages/admin/foodtype/FoodTypeEdit')));
const FoodTypeAdd = Loadable(lazy(() => import('../pages/admin/foodtype/FoodTypeAdd')));

const PartnerShow = Loadable(lazy(() => import('../pages/admin/partner/PartnerShow')));
const PartnerList = Loadable(lazy(() => import('../pages/admin/partner/PartnerList')));

const TrainerTypeList = Loadable(lazy(() => import('../pages/admin/trainertype/TrainerTypeList')));
const TrainerTypeEdit = Loadable(lazy(() => import('../pages/admin/trainertype/TrainerTypeEdit')));
const TrainerTypeAdd = Loadable(lazy(() => import('../pages/admin/trainertype/TrainerTypeAdd')));

const LiquidCategoryList = Loadable(lazy(() => import('../pages/admin/liquidCategory/LiquidCategoryList')));
const LiquidCategoryEdit = Loadable(lazy(() => import('../pages/admin/liquidCategory/LiquidCategoryEdit')));
const LiquidCategoryAdd = Loadable(lazy(() => import('../pages/admin/liquidCategory/LiquidCategoryAdd')));

const WeightList = Loadable(lazy(() => import('../pages/admin/weight/WeightList')));
const WeightEdit = Loadable(lazy(() => import('../pages/admin/weight/WeightEdit')));
const WeightAdd = Loadable(lazy(() => import('../pages/admin/weight/WeightAdd')));

const TargetList = Loadable(lazy(() => import('../pages/admin/target/TargetList')));
const TargetEdit = Loadable(lazy(() => import('../pages/admin/target/TargetEdit')));
const TargetAdd = Loadable(lazy(() => import('../pages/admin/target/TargetAdd')));

const UserLevelList = Loadable(lazy(() => import('../pages/admin/userLevel/UserLevelList')));
const UserLevelEdit = Loadable(lazy(() => import('../pages/admin/userLevel/UserLevelEdit')));
const UserLevelAdd = Loadable(lazy(() => import('../pages/admin/userLevel/UserLevelAdd')));

const ActivityLevelList = Loadable(lazy(() => import('../pages/admin/activityLevel/ActivityLevelList')));
const ActivityLevelEdit = Loadable(lazy(() => import('../pages/admin/activityLevel/ActivityLevelEdit')));
const ActivityLevelAdd = Loadable(lazy(() => import('../pages/admin/activityLevel/ActivityLevelAdd')));

const AgeRangeList = Loadable(lazy(() => import('../pages/admin/age/AgeRangeList')));
const AgeRangeEdit = Loadable(lazy(() => import('../pages/admin/age/AgeRangeEdit')));
const AgeRangeAdd = Loadable(lazy(() => import('../pages/admin/age/AgeRangeAdd')));

const BmiRangeList = Loadable(lazy(() => import('../pages/admin/bmi/BmiRangeList')));
const BmiRangeEdit = Loadable(lazy(() => import('../pages/admin/bmi/BmiRangeEdit')));
const BmiRangeAdd = Loadable(lazy(() => import('../pages/admin/bmi/BmiRangeAdd')));

const ExerciseCategoryList = Loadable(lazy(() => import('../pages/admin/exerciseCategory/ExerciseCategoryList')));
const ExerciseCategoryEdit = Loadable(lazy(() => import('../pages/admin/exerciseCategory/ExerciseCategoryEdit')));
const ExerciseCategoryAdd = Loadable(lazy(() => import('../pages/admin/exerciseCategory/ExerciseCategoryAdd')));

const LiquidList = Loadable(lazy(() => import('../pages/admin/liquid/LiquidList')));
const LiquidEdit = Loadable(lazy(() => import('../pages/admin/liquid/LiquidEdit')));
const LiquidAdd = Loadable(lazy(() => import('../pages/admin/liquid/LiquidAdd')));

const MuscleCategoryList = Loadable(lazy(() => import('../pages/admin/muscleCategory/MuscleCategoryList')));
const MuscleCategoryEdit = Loadable(lazy(() => import('../pages/admin/muscleCategory/MuscleCategoryEdit')));
const MuscleCategoryAdd = Loadable(lazy(() => import('../pages/admin/muscleCategory/MuscleCategoryAdd')));

const HeightList = Loadable(lazy(() => import('../pages/admin/height/HeightList')));
const HeightEdit = Loadable(lazy(() => import('../pages/admin/height/HeightEdit')));
const HeightAdd = Loadable(lazy(() => import('../pages/admin/height/HeightAdd')));

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const GeneralAdmin = Loadable(lazy(() => import('../pages/dashboard/GeneralAdmin')));
const General404 = Loadable(lazy(() => import('../pages/Page404')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));

const TransactionList = Loadable(lazy(() => import('../pages/admin/transaction/transactionList')));
const TermsAndCondition = Loadable(lazy(() => import('../pages/info-pages/TermsAndCondition')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/info-pages/PrivacyPolicy')));

const ChatUser = Loadable(lazy(() => import('../pages/chat/user')));
