// routes
import { PATH_ADMIN_DASHBOARD, PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import useAuth from '../../hooks/useAuth';
import { PaymentsOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  group: getIcon('ic_group'),
  trainer: getIcon('ic_trainer'),
  exercises: getIcon('ic_exercises'),
  workout: getIcon('ic_workout'),
  meals: getIcon('ic_meals'),
  programs: getIcon('ic_programs'),
  settings: getIcon('ic_settings')
};

const sidebarConfigAdmin = [
  {
    subheader: 'menu',
    items: [
      { title: 'app', path: PATH_ADMIN_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Transactions', path: PATH_ADMIN_DASHBOARD.transaction.root, icon: <PaymentsOutlined /> },
      { title: 'Partners', path: PATH_ADMIN_DASHBOARD.partner.list, icon: ICONS.user },

      {
        title: 'Categories',
        path: '',
        icon: ICONS.settings,
        children: [
          { title: 'Food', path: PATH_ADMIN_DASHBOARD.food.list, icon: ICONS.settings },
          { title: 'Foods Type', path: PATH_ADMIN_DASHBOARD.foodType.list, icon: ICONS.settings },
          { title: 'Trainer Type', path: PATH_ADMIN_DASHBOARD.trainerType.list, icon: ICONS.settings },
          { title: 'Liquid ', path: PATH_ADMIN_DASHBOARD.liquid.list, icon: ICONS.settings },
          {
            title: 'Exercise Category',
            path: PATH_ADMIN_DASHBOARD.exerciseCategory.list,
            icon: ICONS.settings
          },
          { title: 'Muscle Category', path: PATH_ADMIN_DASHBOARD.muscleCategory.list, icon: ICONS.settings },
          { title: 'Liquid Category', path: PATH_ADMIN_DASHBOARD.liquidCategory.list, icon: ICONS.settings },
          { title: 'Weight', path: PATH_ADMIN_DASHBOARD.weight.list, icon: ICONS.settings },
          { title: 'Target', path: PATH_ADMIN_DASHBOARD.target.list, icon: ICONS.settings },
          { title: 'User Level', path: PATH_ADMIN_DASHBOARD.userLevel.list, icon: ICONS.settings },
          { title: 'Activity Level', path: PATH_ADMIN_DASHBOARD.activityLevel.list, icon: ICONS.settings },
          { title: 'Age Range', path: PATH_ADMIN_DASHBOARD.ageRange.list, icon: ICONS.settings },
          { title: 'BMI Range', path: PATH_ADMIN_DASHBOARD.bmiRange.list, icon: ICONS.settings },
          { title: 'Height', path: PATH_ADMIN_DASHBOARD.height.list, icon: ICONS.settings }
        ]
      }
    ]
  }
];

const sidebarConfig = [
  {
    subheader: 'menu',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Users', path: PATH_DASHBOARD.users.list, icon: ICONS.user },
      { title: 'group', path: PATH_DASHBOARD.group.list, icon: ICONS.group },
      { title: 'Trainers', path: PATH_DASHBOARD.trainers.list, icon: ICONS.trainer },
      { title: 'Exercises', path: PATH_DASHBOARD.exercises.list, icon: ICONS.exercises },
      { title: 'Workouts', path: PATH_DASHBOARD.workouts.list, icon: ICONS.workout },
      { title: 'Meals', path: PATH_DASHBOARD.meals.list, icon: ICONS.meals },
      { title: 'Programs', path: PATH_DASHBOARD.programs.list, icon: ICONS.programs }
    ]
  }
];

export { sidebarConfig, sidebarConfigAdmin };
