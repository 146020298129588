// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import { varFadeInDown } from '../components/animate';

// ----------------------------------------------------------------------

export default function DocIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box {...other}>
      <motion.div variants={varFadeInDown}>
        <Box component="img" src="/static/illustrations/paymantConnectSuccess.svg" sx={{ maxHeight: 320 }} />
      </motion.div>
    </Box>
  );
}
