import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_ADMIN_DASHBOARD, PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  if (isAuthenticated) {
    let path = PATH_DASHBOARD.root;
    if (user?.role === 'admin') {
      path = PATH_ADMIN_DASHBOARD.root;
    }
    return <Navigate to={path} />;
  }

  return <>{children}</>;
}
