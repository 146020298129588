import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack, Typography, Button, Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import { ResetPasswordForm } from '../reset-password';
import { SentIcon } from '../../../assets';
import { useState } from 'react';
import { Container } from '@mui/material';

// ----------------------------------------------------------------------

export default function ChangePasswordForm({ token, email, userType }) {
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { savePassword } = useAuth();
  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
      token: token,
      email: email
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await savePassword(values);
        enqueueSnackbar('Change password successful', { variant: 'success' });
        if (!userType) {
          navigate('/auth/login');
        } else {
          setSent(true);
        }
      } catch (error) {
        enqueueSnackbar(error?.errors?.email, { variant: 'error' });
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          {!sent ? (
            <>
              <Typography variant="h3" paragraph>
                Change your password
              </Typography>
              <TextField
                fullWidth
                {...getFieldProps('password')}
                type="password"
                label="Password"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                {...getFieldProps('password_confirmation')}
                type="password"
                label="Confirm password"
                error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                helperText={touched.password_confirmation && errors.password_confirmation}
              />

              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Change password
              </LoadingButton>
            </>
          ) : (
            <Container>
              <Box sx={{ maxWidth: 480, mx: 'auto' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                  <Typography variant="h3" gutterBottom>
                    Change password successful
                  </Typography>
                  <Typography>Go to application and login</Typography>
                </Box>
              </Box>
            </Container>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
