import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Container, Typography, Button } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {}
  };

  return (
    <RootStyle title="Login | mPower">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Welcome to mPower
          </Typography>
          <img src="/static/illustrations/cover.webp" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <RouterLink to={'/'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 512 512">
                  <path
                    d="M93.9658 414.16L382.599 125.527C424.68 83.4459 492.906 83.4459 534.987 125.527L550 140.54L185.173 505.367L93.9658 414.16Z"
                    fill="#42CE10"
                  />
                  <path
                    d="M461.558 325.717L476.57 340.73C518.651 382.81 518.651 451.037 476.57 493.117L461.558 508.13L370.351 416.923L461.558 325.717Z"
                    fill="#42CE10"
                  />
                  <path
                    d="M0 228.987L197.427 31.5606C239.507 -10.5201 307.734 -10.5202 349.814 31.5605L364.827 46.5735L91.2069 320.194L0 228.987Z"
                    fill="#42CE10"
                  />
                </svg>
              </RouterLink>
              <Typography variant="h3" gutterBottom>
                Sign in mPower
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>
          </Stack>

          {method === 'firebase' && <AuthFirebaseSocials />}

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              Login
            </Button>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
