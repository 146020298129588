// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  return (
    <MAvatar
      src={user?.profile?.avatar_uris?.small}
      alt={user?.profile?.first_name}
      color={user?.photoURL ? 'default' : createAvatar(user?.profile?.first_name).color}
      {...other}
    >
      {createAvatar(user?.profile?.first_name).name}
    </MAvatar>
  );
}
