// ----------------------------------------------------------------------

import ChangePassword from '../pages/authentication/ChangePassword';

function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS_CHANGE_PASS = '/api/auth';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_DASHBOARD_ADMIN = '/admin';
const ROOT = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/signup'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/complete'),
  changePassword: path(ROOTS_CHANGE_PASS, '/password/reset/:params'),
  privacyPolicy: path(ROOTS_AUTH, '/privacy-policy'),
  termsAndCondition: path(ROOTS_AUTH, '/terms-and-condition')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_DASHBOARD_ADMIN,
  general: {
    app: path(ROOTS_DASHBOARD_ADMIN, '/app'),
    profile: path(ROOTS_DASHBOARD_ADMIN, '/profile')
  },
  transaction: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/transaction')
  },
  partner: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/partner'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/partner/list'),
    show: path(ROOTS_DASHBOARD_ADMIN, '/partner/show/:id')
  },
  foodType: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/food-type'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/food-type/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/food-type/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/food-type/edit/:id')
  },
  food: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/food'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/food/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/food/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/food/edit/:id')
  },
  trainerType: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type/edit/:id')
  },
  liquidCategory: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category/edit/:id')
  },
  weight: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/weight'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/weight/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/weight/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/weight/edit/:id')
  },
  target: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/target'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/target/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/target/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/target/edit/:id')
  },
  userLevel: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/user-level'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/user-level/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/user-level/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/user-level/edit/:id')
  },
  activityLevel: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/activity-level'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/activity-level/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/activity-level/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/activity-level/edit/:id')
  },
  ageRange: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/age-range'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/age-range/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/age-range/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/age-range/edit/:id')
  },
  bmiRange: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range/edit/:id')
  },
  exerciseCategory: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category/edit/:id')
  },
  liquid: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/liquid'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/liquid/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/liquid/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/liquid/edit/:id')
  },
  muscleCategory: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category/edit/:id')
  },
  height: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/height'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/height/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/height/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/height/edit/:id')
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  // ROOT MENU
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
    show: path(ROOTS_DASHBOARD, '/users/show/:id'),
    add: path(ROOTS_DASHBOARD, '/users/add'),
    edit: path(ROOTS_DASHBOARD, '/users/edit/:id'),
    profile: path(ROOTS_DASHBOARD, '/users/profile')
  },
  group: {
    root: path(ROOTS_DASHBOARD, '/group'),
    list: path(ROOTS_DASHBOARD, '/group/list'),
    show: path(ROOTS_DASHBOARD, '/group/show/:id'),
    add: path(ROOTS_DASHBOARD, '/group/add'),
    edit: path(ROOTS_DASHBOARD, '/group/edit/:id')
  },
  trainers: {
    root: path(ROOTS_DASHBOARD, '/trainers'),
    list: path(ROOTS_DASHBOARD, '/trainers/list'),
    show: path(ROOTS_DASHBOARD, '/trainers/show/:id'),
    add: path(ROOTS_DASHBOARD, '/trainers/add'),
    edit: path(ROOTS_DASHBOARD, '/trainers/edit/:id')
  },
  exercises: {
    root: path(ROOTS_DASHBOARD, '/exercises'),
    list: path(ROOTS_DASHBOARD, '/exercises/list'),
    show: path(ROOTS_DASHBOARD, '/exercises/show/:id'),
    add: path(ROOTS_DASHBOARD, '/exercises/add'),
    edit: path(ROOTS_DASHBOARD, '/exercises/edit/:id')
  },
  workouts: {
    root: path(ROOTS_DASHBOARD, '/workouts'),
    list: path(ROOTS_DASHBOARD, '/workouts/list'),
    show: path(ROOTS_DASHBOARD, '/workouts/show/:id'),
    add: path(ROOTS_DASHBOARD, '/workouts/add'),
    edit: path(ROOTS_DASHBOARD, '/workouts/edit/:id')
  },
  meals: {
    root: path(ROOTS_DASHBOARD, '/meals'),
    list: path(ROOTS_DASHBOARD, '/meals/list'),
    show: path(ROOTS_DASHBOARD, '/meals/show/:id'),
    add: path(ROOTS_DASHBOARD, '/meals/add'),
    edit: path(ROOTS_DASHBOARD, '/meals/edit/:id')
  },
  programs: {
    root: path(ROOTS_DASHBOARD, '/programs'),
    list: path(ROOTS_DASHBOARD, '/programs/list'),
    show: path(ROOTS_DASHBOARD, '/programs/show/:id'),
    add: path(ROOTS_DASHBOARD, '/programs/add'),
    edit: path(ROOTS_DASHBOARD, '/programs/edit/:id')
  }
};

export const PATH_DOCS = '';
