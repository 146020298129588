import React, { useEffect } from 'react';

import './widget';
import useAuth from 'src/hooks/useAuth';
import { chatConfig } from 'src/config';
import { CometChat } from '@cometchat-pro/chat';

import './chat.css';

export default function Chat() {
  const { user } = useAuth();
  const { appID, appRegion, authKey, widgetID } = chatConfig;

  useEffect(() => {
    initChat();
  }, []);

  const initChat = async () => {
    // Init chat instance
    const settings = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(appRegion).build();

    await CometChat.init(appID, settings);

    // Create user instance
    const uid = `agent_${user.id}`;
    const userInstance = new CometChat.User(uid);
    userInstance.setName(user?.full_name);
    userInstance.setAvatar(user.profile.avatar_uris.small);
    userInstance.setRole('agent');
    try {
      await CometChat.updateUser(userInstance, authKey);
    } catch (e) {
      if (e.code == 'ERR_UID_NOT_FOUND') {
        await CometChat.createUser(userInstance, authKey);
      }
    }

    // Init widget
    try {
      await CometChatWidget.init({ appID, appRegion, authKey });
      try {
        await CometChatWidget.login({ uid });
        CometChatWidget.launch({
          widgetID,
          docked: 'true',
          alignment: 'right',
          roundedCorners: 'true',
          height: '450px',
          width: '400px',
          zIndex: -1
        });
      } catch (e) {}
    } catch (e) {}
  };

  return <></>;
}
