import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import { API_URL } from '../config';
import { CometChat } from '@cometchat-pro/chat';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getMe: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('token');
        if (accessToken) {
          setSession(accessToken);
          const response = await axios.get(`${API_URL}/auth/get_me`);
          const user = response.data.data;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, is_user) => {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email,
      password,
      is_user
    });

    const { token, user, status } = response.data;
    if (status) {
      setSession(token);
      dispatch({ type: 'LOGIN', payload: { user } });
    } else {
      return response.data;
    }
  };

  const register = async (data) => {
    const response = await axios.post(`${API_URL}/invited/register`, data);
    const { token, user } = response.data;
    setSession(token);
    // window.localStorage.setItem('token', token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    // const asd = await CometChat.logout();
    const { data } = await axios.post(`${API_URL}/auth/logout`);
    if (data?.status) {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    }
  };

  const resetPassword = async (email) => {
    return await axios.post(`${API_URL}/auth/password/email`, { email: email });
  };

  const savePassword = async (data) => {
    const response = await axios.post(`${API_URL}/auth/password/reset`, data);
  };

  const updateProfile = (user) => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  const getMe = async () => {
    const response = await axios.get(`${API_URL}/auth/get_me`);
    const user = response.data.data;
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        savePassword,
        getMe
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
