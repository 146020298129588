// ----------------------------------------------------------------------

import axios from './axios';
import { API_URL } from '../config';

export default function fakeRequest(time) {
  return new Promise((res) => setTimeout(res, time));
}

const ChangePasswordProfile = async (data) => {
  try {
    return await axios.post(`${API_URL}/change-password`, data);
  } catch (e) {
    return e;
  }
};

const ProfileUpdate = async (data) => {
  try {
    const cloneData = JSON.parse(JSON.stringify(data))
    if (data.imageFile) {
      let id = await ProfileUpdateAvatar(data.imageFile);
      await ProfileAvatarSave(id);
    }
    delete cloneData.photoURL;
    delete cloneData.imageFile;
    delete data.imageFile;
    if(typeof cloneData.phone !== 'object') {
      cloneData.phone = { phone_code: cloneData.countryCode, number: cloneData.phone };
    }
    delete cloneData.countryCode;
    const res = await axios.put(`${API_URL}/v2/user/profile`, cloneData);
    return {...(res?.data?.user || {}), status: res.status};
  } catch (e) {
    return e;
  }
};

const GymUpdate = async (data) => {
  try {
    const cloneData = JSON.parse(JSON.stringify(data))
    if (data.imageFile) {
      let id = await ProfileUpdateAvatar(data.imageFile);
      await GymAvatarSave(id);
    }
    delete cloneData.photoURL;
    delete cloneData.imageFile;
    delete data.imageFile;
    const res = await axios.put(`${API_URL}/v2/gym`, data);
    return res?.data;
  } catch (e) {
    return e;
  }
};

const ProfileUpdateAvatar = async (file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(`${API_URL}/upload/image`, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {}
};

const ProfileAvatarSave = async (id) => {
  try {
    return await axios.post(`${API_URL}/images/crop/avatar`, {
      file: id
    });
  } catch (e) {}
};

const GymAvatarSave = async (id) => {
  try {
    return await axios.post(`${API_URL}/images/crop/image-gym`, {
      file: id
    });
  } catch (e) {}
};
const CreateExercises = async (data) => {
  try {
    let res = await axios.post(`${API_URL}/v2/exercise`, data);

    if (data.imageFile) {
      let id = await ExercisesUploadImage(data.imageFile);
      await ExercisesImageSave(id, res.data.exercise.id);
    }
    return res;
  } catch (e) {
    return e
  }
};

const UpdateExercises = async (data, id) => {
  try {
    let res = await axios.put(`${API_URL}/v2/exercise/${id}`, data);

    if (data.imageFile) {
      let id = await ExercisesUploadImage(data.imageFile);
      await ExercisesImageSave(id, res.data.exercise.id);
    }
    return res;
  } catch (e) {
    return e
  }
};

const ExercisesUploadImage = async (file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(`${API_URL}/upload/image`, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {}
};

const ExercisesImageSave = async (id, eid) => {
  try {
    return await axios.post(`${API_URL}/images/crop/image-exercise`, {
      file: id,
      exercise_id: eid
    });
  } catch (e) {}
};

const CreateWorkout = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/workout`, data);
  } catch (e) { return e }
};

const UpdateWorkout = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/workout/${id}`, data);
  } catch (e) { return e }
};

const CreateGroup = async (params) => {
  try {
    const imageFile = params?.groupImage;
    params.groupImage = {};
    const response = await axios.post(`${API_URL}/v2/group`, params);
    const imageId = await uploadImageGroup(`${API_URL}/upload/image`, imageFile);
    await imageGroup(imageId, response.data.group.id);
  } catch (e) {}
};

const UpdateGroup = async (params, id) => {
  try {
    const imageFile = params?.groupImage;
    params.groupImage = {};
    const response = await axios.put(`${API_URL}/v2/group/${id}`, params);
    if (imageFile) {
      const imageId = await uploadImageGroup(`${API_URL}/upload/image`, imageFile);
      await imageGroup(imageId, response.data.group.id);
    }
  } catch (e) {}
};

const uploadImageGroup = async (url, file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(url, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {}
};

const imageGroup = async (imageId, groupId) => {
  try {
    const response = await axios.post(`${API_URL}/images/crop/image-group`, {
      file: imageId,
      group_id: groupId
    });
    return response;
  } catch (e) {}
};

const InviteTrainer = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/invite`, data);
  } catch (e) {
    return e;
  }
};

const CreateMeals = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/meal`, data);
  } catch (e) {
    return e;
  }
};
const UpdateMeals = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/meal/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateFootType = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/food-category`, data);
  } catch (e) {
    return e;
  }
};
const GetFoodTypeById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/food-category/${id}`);
  } catch (e) {
    return e;
  }
};
const UpdateFoodType = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/food-category/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateFoot = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/food`, data);
  } catch (e) {
    return e;
  }
};

const UpdateFood = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/food/${id}`, data);
  } catch (e) {
    return e;
  }
};

const GetFoodById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/food/${id}`);
  } catch (e) {
    return e;
  }
};
const GetExerciseType = async () => {
  try {
    return await axios.post(`${API_URL}/v2/getData`, {
      fields: ['liquids', 'foods', 'workouts', 'meals', 'trainer-types']
    });
  } catch (e) {
    return e;
  }
};

const CreateProgramRequest = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/program`, data);
  } catch (e) {
    return e;
  }
};
const UpdateProgramRequest = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/program/${id}`, data);
  } catch (e) {
    return e;
  }
};
const GetProgramById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/program/${id}`);
  } catch (e) {
    return e;
  }
};

const GetMealById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/meal/${id}`);
  } catch (e) {
    return e;
  }
};
const GetWorkoutById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/workout/${id}`);
  } catch (e) {
    return e;
  }
};

const DeleteItemRequest = async (path, id) => {
  try {
    return await axios.post(`${API_URL}/v2/${path}/${id}`, { _method: 'DELETE' });
  } catch (e) {
    return e;
  }
};

const GetListDataRequest = async (path, page, search, limit) => {
  try {
    return await axios.get(`${API_URL}/v2/${path}?page=${page}&limit=${limit}&search=${search}`);
  } catch (e) {
    return e;
  }
};

const GetUserList = async (page, role, search, limit, filters) => {
  console.log('search', search);
  try {
    return await axios.post(`${API_URL}/v2/users`, {
      page: page,
      role: role,
      search: search,
      limit: limit,
      filters: filters
    });
  } catch (e) {
    return e;
  }
};
const GetUserProfileById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}`);
  } catch (e) {
    return e;
  }
};
const GetUserProgramOverview = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/overview`);
  } catch (e) {
    return e;
  }
};
const GetUserProgramActions = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/actions`);
  } catch (e) {
    return e;
  }
};

const GetUserProgramList = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/programs`);
  } catch (e) {
    return e;
  }
};
const GetUserProgramActionsHistory = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/actions?history=true`);
  } catch (e) {
    return e;
  }
};

const GetOverview = async () => {
  try {
    return await axios.get(`${API_URL}/v2/overview`);
  } catch (e) {
    return e;
  }
};

const GetDataRequest = async (type) => {
  try {
    return await axios.post(`${API_URL}/v2/getData`, {
      fields: type
    });
  } catch (e) {
    return e;
  }
};
const GetExerciseItem = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/exercise/${id}`);
  } catch (e) {
    return e;
  }
};
// --------------------------------------123-----------------------------------------

const CreateLiquidCategory = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/liquid-category`, data);
  } catch (e) {
    return e;
  }
};

const UpdateLiquidCategory = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/liquid-category/${id}`, data);
  } catch (e) {
    return e;
  }
};

const GetLiquidCategoryById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/liquid-category/${id}`);
  } catch (e) {
    return e;
  }
};

const GetLiquidCategories = async (data) => {
  try {
    return await axios.get(`${API_URL}/v2/liquid-category`, data);
  } catch (e) {
    return e;
  }
};
const GetTrainerTypeId = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/trainer-type/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateTrainerType = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/trainer-type/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateTrainerType = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/trainer-type`, data);
  } catch (e) {
    return e;
  }
};

const GetWeightById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/weight/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateWeight = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/weight/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateWeight = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/weight`, data);
  } catch (e) {
    return e;
  }
};
const GetTargetById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/target/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateTarget = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/target/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateTarget = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/target`, data);
  } catch (e) {
    return e;
  }
};
const GetUserLevelById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/user-level/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateUserLevel = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/user-level/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateUserLevel = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/user-level`, data);
  } catch (e) {
    return e;
  }
};

const GetActivityLevelById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/activity-level/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateActivityLevel = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/activity-level/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateActivityLevel = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/activity-level`, data);
  } catch (e) {
    return e;
  }
};

const GetAgeRangeById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/age/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateAgeRange = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/age/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateAgeRange = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/age`, data);
  } catch (e) {
    return e;
  }
};
const GetBmiRangeById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/bmi/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateBmiRange = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/bmi/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateBmiRange = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/bmi`, data);
  } catch (e) {
    return e;
  }
};
const GetExerciseCategoryById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/exercise-type/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateExerciseCategory = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/exercise-type/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateExerciseCategory = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/exercise-type`, data);
  } catch (e) {
    return e;
  }
};
const GetMuscleCategoryById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/muscle-type/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateMuscleCategory = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/muscle-type/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateMuscleCategory = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/muscle-type`, data);
  } catch (e) {
    return e;
  }
};
const GetLiquidById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/liquid/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateLiquid = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/liquid/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateLiquid = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/liquid`, data);
  } catch (e) {
    return e;
  }
};
const GetHeightById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/height/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateHeight = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/height/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateHeight = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/height`, data);
  } catch (e) {
    return e;
  }
};
const GetUserPartnerList = async (page, search) => {
  try {
    return await axios.post(`${API_URL}/v2/users`, { page: page, role: 'partner', search: search });
  } catch (e) {
    return e;
  }
};

const SubscribePayment = async () => {
  try {
    return await axios.get(`${API_URL}/v2/subscribe?subscription_id=1`, {});
  } catch (e) {
    return e;
  }
};

const GetGymData = async () => {
  try {
    return await axios.get(`${API_URL}/v2/gym`);
  } catch (e) {
    return e;
  }
};
const RequestExerciseList = async (page) => {
  try {
    return await axios.get(`${API_URL}/v2/exercise?page=${page}`);
  } catch (e) {
    return e;
  }
};

const RequestTransactionList = async (id) => {
  try {
    let url = `${API_URL}/v2/users/purchases_list`;
    if (id) {
      url = `${API_URL}/v2/users/purchases_list/${id}`;
    }
    return await axios.get(url);
  } catch (e) {
    return e;
  }
};

const TransactionsPartner = async () => {
  try {
    return await axios.get(`${API_URL}/v2/users/purchases_list`);
  } catch (e) {
    return e;
  }
};

const GetStripeDashboardLink = async () => {
  try {
    return await axios.get(`${API_URL}/v2/user/stripe/dashboard`);
  } catch (e) {
    return e;
  }
};

const GetStripeBalance = async () => {
  try {
    return await axios.get(`${API_URL}/v2/user/stripe/balance`);
  } catch (e) {
    return e;
  }
};

const StripePayoutRequest = async () => {
  try {
    return await axios.post(`${API_URL}/v2/user/stripe/payout`, {});
  } catch (e) {
    return e;
  }
};
const StripeDisconnect = async () => {
  try {
    return await axios.post(`${API_URL}/v2/user/stripe/remove`, {});
  } catch (e) {
    return e;
  }
};

export {
  GetUserProgramActionsHistory,
  GetUserProgramOverview,
  ChangePasswordProfile,
  GetUserProgramActions,
  CreateProgramRequest,
  UpdateProgramRequest,
  GetUserProfileById,
  GetListDataRequest,
  GetUserProgramList,
  DeleteItemRequest,
  GetExerciseType,
  CreateExercises,
  UpdateExercises,
  GetExerciseItem,
  GetFoodTypeById,
  GetDataRequest,
  GetWorkoutById,
  CreateFootType,
  GetProgramById,
  UpdateFoodType,
  CreateWorkout,
  ProfileUpdate,
  UpdateWorkout,
  InviteTrainer,
  GetFoodById,
  fakeRequest,
  CreateGroup,
  UpdateGroup,
  CreateMeals,
  UpdateMeals,
  GetMealById,
  CreateFoot,
  UpdateFood,
  GetUserList,
  GetOverview,
  GetGymData,
  //
  GetExerciseCategoryById,
  UpdateExerciseCategory,
  CreateExerciseCategory,
  RequestTransactionList,
  GetStripeDashboardLink,
  GetLiquidCategoryById,
  GetMuscleCategoryById,
  CreateLiquidCategory,
  UpdateLiquidCategory,
  GetActivityLevelById,
  UpdateMuscleCategory,
  CreateMuscleCategory,
  GetLiquidCategories,
  UpdateActivityLevel,
  RequestExerciseList,
  CreateActivityLevel,
  StripePayoutRequest,
  TransactionsPartner,
  GetUserPartnerList,
  CreateTrainerType,
  UpdateTrainerType,
  GetTrainerTypeId,
  StripeDisconnect,
  GetUserLevelById,
  SubscribePayment,
  GetStripeBalance,
  GetAgeRangeById,
  CreateUserLevel,
  UpdateUserLevel,
  GetBmiRangeById,
  CreateAgeRange,
  UpdateBmiRange,
  UpdateAgeRange,
  CreateBmiRange,
  GetTargetById,
  GetWeightById,
  GetLiquidById,
  GetHeightById,
  UpdateWeight,
  CreateWeight,
  UpdateTarget,
  CreateTarget,
  UpdateLiquid,
  CreateLiquid,
  UpdateHeight,
  CreateHeight,
  GymUpdate
};
